import { assert, assertIsDefined } from "@utils/assertion";

type ObtainKeys<Obj, Type> = {
  [Prop in keyof Obj]: Obj[Prop] extends Type ? Prop : never;
}[keyof Obj];

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Array<T> {
    // eslint-disable-next-line @typescript-eslint/method-signature-style
    assumeNoNullValues<T>(): NonNullable<T>[];

    // eslint-disable-next-line @typescript-eslint/method-signature-style
    filterNullValues<T>(): NonNullable<T[]>;

    // eslint-disable-next-line @typescript-eslint/method-signature-style
    filterEquals<ObjKey extends ObtainKeys<T, string | number | boolean | bigint>>(this: T[], key: ObjKey, filterValue: T[ObjKey]): T[];

    // eslint-disable-next-line @typescript-eslint/method-signature-style
    filterLike<ObjKey extends ObtainKeys<T, string | number>>(this: T[], key: ObjKey, filterValue: T[ObjKey]): T[];
  }
}

Array.prototype.assumeNoNullValues = function <T>(this: T[]): NonNullable<T>[] {
  return this.map((value) => {
    assertIsDefined(value);
    return value;
  });
};

Array.prototype.filterNullValues = function <T>(this: T[]): NonNullable<T>[] {
  return this.filter((value) => {
    return value !== null && value !== undefined;
  }).map((value) => {
    assertIsDefined(value);
    return value;
  });
};

Array.prototype.filterLike = function <T, ObjKey extends ObtainKeys<T, string | number>>(this: T[], key: ObjKey, filterValue: T[ObjKey]): T[] {
  return this.filter((value) => {
    const tmpValue = value[key];
    assert(typeof tmpValue === "string" || typeof tmpValue === "number");
    assert(typeof filterValue === "string" || typeof filterValue === "number");
    return tmpValue.toString().toLowerCase().includes(filterValue.toString().toLowerCase());
  });
};

Array.prototype.filterEquals = function <T, ObjKey extends ObtainKeys<T, string | number | boolean | bigint>>(this: T[], key: ObjKey, filterValue: T[ObjKey]): T[] {
  return this.filter((value) => value[key] === filterValue);
};

export function isArray(a: unknown): a is unknown[] {
  return Array.isArray(a);
}

export function isTypedArrayOf<T>(a: unknown[], validator: (e: unknown) => boolean): a is T[] {
  return a.map(validator).find((e) => !e) !== false;
}

export function includes<U, T extends U>(coll: readonly T[], el: U): el is T {
  return coll.includes(el as T);
}

export function getLastEntry<T>(list: T[]): T | undefined {
  return list.slice(-1)[0];
}

export type ReadonlyUint8Array = {
  readonly length: number;
  readonly [index: number]: number;
  [Symbol.iterator]: () => IterableIterator<number>;
};
