import type { DeepReadonly } from "vue";
import _cloneDeep from "lodash/cloneDeep";

export type MustInclude<UnionType, ListType extends readonly UnionType[]> = [UnionType] extends [ListType[keyof ListType]] ? ListType : never;

export function isNonNullObject(u: unknown): u is object {
  return typeof u === "object" && u !== null;
}

export type ObjectType<Obj> = Record<string, Obj[keyof Obj]>;
export type ObjectKeyType<Obj> = readonly (keyof Obj)[];

export function objectEntries<
  Obj extends ObjectType<Obj>,
  Keys extends ObjectKeyType<Obj>, // limit to known keys
>(
  obj: Obj,
  keys: MustInclude<keyof Obj, Keys>, // ensure that the keys are in the list
): [key: keyof Obj, value: Obj[keyof Obj]][] {
  return [...new Set(keys)].map((key) => [key, obj[key]]);
}

export function objectValues<
  Obj extends ObjectType<Obj>,
  Keys extends ObjectKeyType<Obj>, // limit to known keys
>(
  obj: Obj,
  keys: MustInclude<keyof Obj, Keys>, // ensure that the keys are in the list
): Obj[keyof Obj][] {
  return objectEntries(obj, keys).map(([_, value]) => value);
}

export function cloneDeep<T>(data: DeepReadonly<T>): T {
  return _cloneDeep(data) as unknown as T;
}
